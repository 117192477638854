import example from './example.gif';
import React from 'react';

export function Preview() {
    return (
        <div className="col col-12 col-lg-3 p-3 d-flex justify-content-center">
            <img
                src={example}
                className="App-logo rounded-3 border border-dark border-2 d-block shadow-lg"
                style={{
                    width: 256 + 'px',
                    height: 256 + 'px'
                }}
                alt="DigiBitties - 1337 Collection Examples"
            />
        </div>
    );
}
