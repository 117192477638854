import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useContract } from './ContractContext';
import { useChain } from './ChainContext';
import * as PropTypes from 'prop-types';
const { getOpenSeaUrlFromId } = require('./DB1Networks');

function Nft(props) {
    return (
        <div className="d-flex flex-column p-1">
            <div className="">
                <img
                    src={props.image.url}
                    style={{
                        width: 256 + 'px',
                        height: 256 + 'px'
                    }}
                    className="App-logo rounded-3 border border-secondary border-1 d-block shadow-lg"
                    alt={'DigiBitties - 1337 Image - #' + props.image.id}
                />
            </div>
            <div className="d-flex justify-content-center">
                <a
                    className="text-light"
                    target="_blank"
                    rel="noreferrer"
                    href={getOpenSeaUrlFromId(
                        props.chainState.chainId,
                        props.image.id
                    )}
                >
                    DigiBitties - 1337 - #{props.image.id}
                </a>
            </div>
        </div>
    );
}

Nft.propTypes = {
    image: PropTypes.any,
    chainState: PropTypes.any
};

export default function Owned() {
    const { state: chainState, correctNetworkSelected } = useChain();
    const { state: contractState } = useContract();

    const [images, setImages] = React.useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = React.useState(null);
    const [sortedIndexes, setSortedIndexes] = React.useState([]);
    const [sortedImages, setSortedImages] = React.useState([]);
    const [pages, setPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const displayCount = 12;
    const pagesToShow = 2;

    React.useEffect(() => {
        if (pages !== 0 && currentPage > pages) {
            setSearchParamsClosure({})(); // clear params if account selected with less than current page
        }
    }, [currentPage, pages]);

    React.useEffect(() => {
        const sorted = [...contractState.tokensOfOwner];
        sorted.sort(function (a, b) {
            return a - b;
        });
        setSortedIndexes(sorted);
    }, [contractState.tokensOfOwner]);

    React.useEffect(() => {
        const start = (currentPage - 1) * displayCount;
        const end = start + displayCount;
        const displayIndexes = sortedIndexes.slice(start, end);
        (async () => {
            const localImages = [];
            await Promise.all(
                displayIndexes.map(async (i) => {
                    const tokenUri = await contractState.contract?.tokenURI(i);
                    if (tokenUri && tokenUri !== '') {
                        const url = tokenUri.replace(
                            'ipfs://',
                            // We can serve directly here...
                            // 'https://digibitties.com/ipfs/'
                            // Uses IPFS on host (main end user could be OpenSea)
                            'https://ipfs.digibitties.com/ipfs/'
                        );
                        const meta = await axios.get(url);
                        const image = {
                            id: i.toString(),
                            url: meta.data.image.replace(
                                'ipfs://',
                                // We can serve directly here...
                                // 'https://digibitties.com/ipfs/'
                                // Uses IPFS on host (main end user could be OpenSea)
                                'https://ipfs.digibitties.com/ipfs/'
                            )
                        };
                        localImages.push(image);
                    }
                })
            );
            // console.log(`set: ${localImages.map((a) => a.id)}`);
            setImages(localImages);
        })();
    }, [currentPage, sortedIndexes]);

    const setSearchParamsClosure = React.useCallback(
        (params) => {
            return function () {
                // console.log('set: %o', params);
                setSearchParams(params);
            };
        },
        [setSearchParams]
    );

    React.useEffect(() => {
        const sorted = [...images];
        sorted.sort((a, b) => {
            return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
        });
        setSortedImages(sorted);
    }, [images]);

    React.useEffect(() => {
        setPages(Math.ceil(sortedIndexes.length / displayCount));
    }, [sortedIndexes]);

    React.useEffect(() => {
        const pageParam = searchParams.get('page');
        const activePage = pageParam ? parseInt(pageParam) : 1;
        setCurrentPage(activePage);
    }, [searchParams]);

    React.useEffect(() => {
        if (pages > 1) {
            const items = [];
            const pagesStart =
                currentPage > pagesToShow + 1 ? currentPage - pagesToShow : 1;
            let pagesEnd =
                currentPage < pages - pagesToShow
                    ? currentPage + pagesToShow
                    : pages;
            pagesEnd =
                pagesEnd < 2 * pagesToShow + 2 ? 2 * pagesToShow + 2 : pagesEnd;
            pagesEnd = pagesEnd > pages ? pages : pagesEnd;
            if (pagesStart > 1) {
                items.push(
                    <Pagination.First
                        key="first"
                        onClick={setSearchParamsClosure({ page: 1 })}
                    />
                );
                const prevPage = currentPage <= 1 ? 1 : currentPage - 1;
                items.push(
                    <Pagination.Prev
                        key="prev"
                        onClick={setSearchParamsClosure({ page: prevPage })}
                    />
                );
                items.push(<Pagination.Ellipsis key="moreBack" disabled />);
            }
            for (let number = pagesStart; number <= pagesEnd; number++) {
                items.push(
                    <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={setSearchParamsClosure({ page: number })}
                    >
                        {number}
                    </Pagination.Item>
                );
            }
            if (pagesEnd < pages) {
                items.push(<Pagination.Ellipsis key="moreAhead" disabled />);
                const nextPage = currentPage >= pages ? pages : currentPage + 1;
                items.push(
                    <Pagination.Next
                        key="next"
                        onClick={setSearchParamsClosure({ page: nextPage })}
                    />
                );
                items.push(
                    <Pagination.Last
                        key="last"
                        onClick={setSearchParamsClosure({ page: pages })}
                    />
                );
            }

            const paginationBasic = (
                <div>
                    <Pagination className="justify-content-center">
                        {items}
                    </Pagination>
                </div>
            );

            setPagination(paginationBasic);
        } else {
            setPagination(<></>);
        }
    }, [pages, currentPage, setSearchParamsClosure]);

    if (
        correctNetworkSelected() &&
        contractState.contract &&
        chainState.accounts &&
        chainState.accounts.length
    ) {
        return (
            <div
                className="d-flex flex-column bg-dark bg-gradient text-light border border-1 border-secondary rounded rounded-3 pt-3"
                style={{ '--bs-bg-opacity': 0.75 }}
            >
                <div className="d-flex justify-content-center">
                    <p>
                        {contractState.tokenName} currently owned by me:{' '}
                        {contractState.tokensOfOwner.length === 0
                            ? 'none'
                            : contractState.tokensOfOwner.length +
                              ' (id' +
                              (contractState.tokensOfOwner.length > 1
                                  ? 's'
                                  : '') +
                              ': ' +
                              compressArr(contractState.tokensOfOwner).join(
                                  ', '
                              ) +
                              ')'}
                    </p>
                </div>
                {pagination}
                <div className="d-flex flex-wrap justify-content-around">
                    {sortedImages.map((image) => (
                        <Nft
                            key={image.id}
                            image={image}
                            chainState={chainState}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

function compressArr(arr) {
    if (arr.length <= 0) {
        return [];
    }
    const length = arr.length;
    let i = 0;
    let j = 0;
    const arrSorted = [...arr];
    arrSorted.sort(function (a, b) {
        return a - b;
    });
    const returnArr = [];
    while (i < length) {
        j = i;
        while (j + 1 < length && arrSorted[j + 1].eq(arrSorted[j].add(1))) {
            j++;
        }
        if (i === j) {
            returnArr.push(`${arrSorted[i]}`);
            i++;
        } else {
            returnArr.push(`${arrSorted[i]}-${arrSorted[j]}`);
            i = j + 1;
        }
    }
    return returnArr;
}
