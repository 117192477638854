import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './ErrorHandler';
import Footer from './Footer';
import Header from './Header';
import { Messages } from './StatusContext';
import Owned from './Owned';
import Minter from './Minter';
import { Bazooka } from './Bazooka';
import { Preview } from './Preview';
import { useContract } from './ContractContext';
import { useChain } from './ChainContext';
import { ethers } from 'ethers';

function MintCount() {
    const { correctNetworkSelected } = useChain();
    const { state: contractState } = useContract();
    const count = (
        <h6 className="p-1">
            {ethers.utils.commify(contractState.numberMinted.toString())} /
            13,337 Minted!
        </h6>
    );
    return correctNetworkSelected() ? count : null;
}
function MiddleRow() {
    return (
        <div className="row align-items-center justify-content-around">
            <Preview />
            <div
                className="col media m-3 p-3 d-flex align-items-center rounded-3 border border-2 border-primary bg-dark bg-gradient text-light"
                style={{ '--bs-bg-opacity': 0.75 }}
            >
                <div className="media-body p-2">
                    <div className="row">
                        <div className="col">
                            <h1 className="mt-0 mb-1 text-warning display-6">
                                <u>
                                    <b>The 1337 Collection</b>
                                </u>
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-between">
                            <h6 className="p-1">
                                1st gen DigiBitties are <u>now</u> mintable!
                            </h6>
                            <MintCount />
                        </div>
                    </div>
                    <hr />
                    What&apos;s up! Taz here with my bro Digitz, and we are
                    super stoked to bring our first NFT collection to market!
                    <hr />
                    We love to build things, and we&apos;d like to create a game
                    with these NFTs. Every purchase will support us in our
                    journey and as a reward for our community support we will
                    give away one 1337 to every wallet that purchases 10 or
                    more! To claim your free 1337 visit us in{' '}
                    <a
                        href="https://discord.gg/u5QHCzAmqB"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our discord
                    </a>
                    .
                    <br />
                </div>
            </div>
            <Bazooka />
        </div>
    );
}

export default function DApp(props) {
    return (
        <div className="dapp">
            <ErrorBoundary FallbackComponent={ErrorHandler}>
                <Header />
                <div className="container" id="content">
                    <MiddleRow />
                    <Minter />
                    <Owned />
                </div>
                <Footer />
                <Messages />
            </ErrorBoundary>
        </div>
    );
}
