import React, { useState, useEffect } from 'react';
import { ethers, BigNumber } from 'ethers';
import Spinner from 'react-bootstrap/Spinner';
import { useChain } from './ChainContext';
import { useContract } from './ContractContext';
import { useStatus } from './StatusContext';

export default function Minter() {
    const [quantity, setQuantity] = useState(BigNumber.from(1));
    const [amount, setAmount] = useState(BigNumber.from(0));
    const { state: chainState, correctNetworkSelected } = useChain();
    const { state: contractState } = useContract();
    const { addError, addMessage, addContractError } = useStatus();

    const mint = () => {
        contractState.contract
            .mint(quantity, { value: amount })
            .then((tx) => {
                addMessage(
                    <div>
                        Mint transaction was successfully submitted, waiting for
                        block...{' '}
                        <Spinner
                            animation="border"
                            variant="success"
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>,
                    'success',
                    10000
                );
                tx.wait()
                    .then((receipt) => {
                        addMessage('Mint completed.', 'success');
                    })
                    .catch((e) => addContractError(contractState.contract, e));
            })
            .catch((e) => addContractError(contractState.contract, e));
    };

    const teamMint = () => {
        contractState.contract
            .teamMint(quantity)
            .then(() => {
                addMessage(
                    <div>
                        Team mint transaction was successfully submitted,
                        waiting for block...{' '}
                        <Spinner
                            animation="border"
                            variant="success"
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>,
                    'success'
                );
            })
            .catch((e) => addContractError(contractState.contract, e));
    };

    useEffect(() => {
        if (
            chainState.connected &&
            correctNetworkSelected() &&
            quantity &&
            contractState.price &&
            chainState.balance &&
            chainState.accounts &&
            chainState.accounts.length
        ) {
            let newAmount = 0;
            try {
                newAmount = contractState.price.mul(quantity);
            } catch (e) {
                addError(e);
                return;
            }

            if (!amount.eq(newAmount)) {
                setAmount(newAmount);
                if (quantity <= 0) {
                    addMessage(`Cannot buy ${quantity}!`, 'warning');
                } else if (
                    chainState.balance.gt(0) &&
                    newAmount.gt(chainState.balance)
                ) {
                    addMessage(
                        `Not enough ETH (${ethers.utils.formatEther(
                            newAmount
                        )} required) to purchase ${quantity}`,
                        'warning'
                    );
                }
            }
        }
    }, [quantity, contractState, chainState]);

    if (
        correctNetworkSelected() &&
        contractState.contract &&
        chainState.accounts &&
        chainState.accounts.length
    ) {
        let quantClassName = 'form-control';
        if (amount.gt(chainState.balance)) {
            quantClassName += ' is-invalid';
        }
        let teamClassName =
            'm-2 shadow-lg btn btn-primary align-middle border border-secondary border-1 bg-gradient';
        if (!contractState.teamCredits || contractState.teamCredits.eq(0)) {
            teamClassName += ' d-none';
        }
        return (
            <div className="row align-middle mb-2">
                <div className="form-floating col col-6 col-lg-3">
                    <input
                        autoFocus
                        type="number"
                        className={
                            'border border-secondary border-1 ' + quantClassName
                        }
                        id="quantityInput"
                        min="1"
                        placeholder="1337"
                        name="quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                    <label htmlFor="quantityInput">Mint how many?</label>
                </div>
                <div className="form-floating col col-6 col-lg-3 align-middle">
                    <button
                        type="button"
                        className="m-2 btn btn-primary align-middle bg-gradient border border-secondary border-1"
                        onClick={mint}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-cart-check-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                        </svg>
                        &nbsp; Mint
                    </button>
                    <button
                        type="button"
                        className={teamClassName}
                        onClick={teamMint}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-cart-check-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                        </svg>
                        &nbsp; Team Mint ({contractState.teamCredits.toString()}
                        )
                    </button>
                </div>
                <div className="form-floating col col-6 col-lg-3 align-middle">
                    <input
                        type="text"
                        readOnly
                        className="form-control text-muted border border-secondary"
                        id="amountInput"
                        placeholder="0"
                        name="amount"
                        value={ethers.utils.formatEther(amount) + ' ETH'}
                    />
                    <label htmlFor="amountInput">Total price</label>
                </div>
                <div className="form-floating col col-6 col-lg-3">
                    <input
                        type="text"
                        readOnly
                        className="form-control text-muted border border border-secondary border-1"
                        id="priceInput"
                        placeholder="0"
                        name="price"
                        value={
                            ethers.utils.formatEther(contractState.price) +
                            ' ETH'
                        }
                    />
                    <label htmlFor="priceInput">Price each</label>
                </div>
            </div>
        );
    }
}
