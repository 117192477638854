import React from 'react';

const Images = () => {
    return (
        <div>
            <h1>Welcome to DigiBitties, images coming soon!</h1>
        </div>
    );
};

export default Images;
