import React from 'react';
import { BigNumber, ethers } from 'ethers';
import { getFromId } from './DB1Networks';
import { useChain } from './ChainContext';
import DB1_ABI from './DB1ABI.json';
import { useStatus } from './StatusContext';

const initialState = {
    contractBalance: BigNumber.from(0),
    contract: null,
    tokenName: '',
    teamCredits: BigNumber.from(0),
    tokensOfOwner: [],
    numberMinted: BigNumber.from(0),
    price: BigNumber.from(0),
    owner: null
};

const ContractContext = React.createContext();

export const contractActions = {
    balanceChanged: 'BALANCE_CHANGED',
    contractChanged: 'CONTRACT_CHANGED',
    nameChanged: 'NAME_CHANGED',
    priceChanged: 'PRICE_CHANGED',
    teamCreditsChanged: 'TEAM_CREDITS_CHANGED',
    numberMintedChanged: 'NUMBER_MINTED_CHANGED',
    ownedNftsChanged: 'OWNED_NFTS_CHANGED',
    ownerChanged: 'OWNER_CHANGED'
};

function useContract() {
    const context = React.useContext(ContractContext);
    if (!context) {
        throw new Error(`useContract must be used within a ContractProvider`);
    }
    const [state, dispatch] = context;

    const setOwnedNfts = (payload) =>
        dispatch({ type: contractActions.ownedNftsChanged, payload });

    return { state, dispatch, setOwnedNfts };
}

function contractReducer(state, action) {
    switch (action.type) {
        case contractActions.balanceChanged: {
            // console.log(
            //     'current balance is: %o -> setting balance to: %o',
            //     state.contractBalance,
            //     action.payload
            // );
            return { ...state, contractBalance: action.payload };
        }
        case contractActions.contractChanged: {
            return { ...state, contract: action.payload };
        }
        case contractActions.nameChanged: {
            return { ...state, tokenName: action.payload };
        }
        case contractActions.priceChanged: {
            return { ...state, price: action.payload };
        }
        case contractActions.teamCreditsChanged: {
            return { ...state, teamCredits: action.payload };
        }
        case contractActions.numberMintedChanged: {
            return { ...state, numberMinted: action.payload };
        }
        case contractActions.ownedNftsChanged: {
            return { ...state, tokensOfOwner: action.payload };
        }
        case contractActions.ownerChanged: {
            return { ...state, owner: action.payload };
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`);
        }
    }
}

// function setTeamCredits(dispatch) {
//     return (payload) =>
//         dispatch({
//             type: contractActions.teamCreditsChanged,
//             payload
//         });
// }
//
function ContractProvider(props) {
    const [state, dispatch] = React.useReducer(contractReducer, initialState);
    const { state: chainState, correctNetworkSelected } = useChain();
    const { addContractError } = useStatus();

    // const value = [state, dispatch];
    const value = React.useMemo(() => {
        return [state, dispatch];
    }, [state]);

    React.useEffect(() => {
        const updateBalance = (newBalance) => {
            dispatch({
                type: contractActions.balanceChanged,
                payload: newBalance
            });
        };

        const blockListener = (number) => {
            const address = state.contract.address;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            provider.getBalance(address).then(updateBalance);
        };

        if (
            chainState.connected &&
            correctNetworkSelected() &&
            state.contract
        ) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            provider.getBlockNumber().then(blockListener);
            provider.on('block', blockListener);
            return () => provider.off('block', blockListener);
        }
    }, [state.contract?.address, chainState.connected]);

    React.useEffect(() => {
        if (chainState.connected && correctNetworkSelected()) {
            const network = getFromId(chainState.chainId);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            // const signer =
            //     chainState.accounts && chainState.accounts.length
            //         ? chainState.accounts[0]
            //         : null;
            const contract =
                network && signer
                    ? new ethers.Contract(network.address, DB1_ABI, signer)
                    : null;
            dispatch({
                type: contractActions.contractChanged,
                payload: contract
            });
        }
    }, [chainState.chainId, chainState.connected, correctNetworkSelected]);

    React.useEffect(() => {
        if (correctNetworkSelected()) {
            state.contract
                ?.name()
                .then((payload) =>
                    dispatch({ type: contractActions.nameChanged, payload })
                );
            state.contract
                ?.price()
                .then((payload) =>
                    dispatch({ type: contractActions.priceChanged, payload })
                );
        } else {
            dispatch({ type: contractActions.nameChanged, payload: '' });
            dispatch({
                type: contractActions.priceChanged,
                payload: BigNumber.from(0)
            });
        }
    }, [state.contract?.address, chainState.chainId]);

    const requestTeamCredits = () => {
        state.contract
            ?.teamCredits(chainState.accounts[0])
            .then((payload) =>
                dispatch({ type: contractActions.teamCreditsChanged, payload })
            )
            .catch((e) => addContractError(state.contract, e));
    };

    const requestNumberMinted = () => {
        state.contract
            ?.totalSupply()
            .then((payload) =>
                dispatch({ type: contractActions.numberMintedChanged, payload })
            )
            .catch((e) => addContractError(state.contract, e));
    };

    const requestTokensOfOwner = () => {
        state.contract
            ?.tokensOfOwner(chainState.accounts[0])
            .then((payload) => {
                dispatch({ type: contractActions.ownedNftsChanged, payload });
            })
            .catch((e) => addContractError(state.contract, e));
    };

    const requestOwner = () => {
        state.contract
            ?.owner()
            .then((payload) => {
                dispatch({ type: contractActions.ownerChanged, payload });
            })
            .catch((e) => addContractError(state.contract, e));
    };

    React.useEffect(() => {
        if (
            correctNetworkSelected() &&
            chainState.accounts &&
            chainState.accounts.length
        ) {
            requestNumberMinted();
            requestTeamCredits();
            requestTokensOfOwner();
            requestOwner();
            const onTransfer = (from, to, id) => {
                // console.log('Transfer event detected...');
                const account = chainState.accounts[0].toUpperCase();
                from = from.toUpperCase();
                to = to.toUpperCase();
                if (from === account || to === account) {
                    requestNumberMinted();
                    requestTeamCredits();
                    requestTokensOfOwner();
                }
            };
            state.contract?.on('Transfer', onTransfer);
            return () => state.contract?.off('Transfer', onTransfer);
        } else {
            dispatch({ type: contractActions.teamCreditsChanged, payload: 0 });
            dispatch({ type: contractActions.ownedNftsChanged, payload: [] });
            dispatch({ type: contractActions.ownerChanged, payload: null });
        }
    }, [chainState.accounts, chainState.chainId]);

    return (
        <ContractContext.Provider
            value={value}
            // value={{ state, dispatch, onReloadNeeded }}
            {...props}
        />
    );
}

export { ContractProvider, useContract };
