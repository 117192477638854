import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DApp from './DApp';
import Claim from './Claim';
import ComingSoon from './ComingSoon';
import { Alert, Container, Nav, Navbar } from 'react-bootstrap';
import Images from './Images';
import { ChainProvider } from './ChainContext';
import { StatusProvider } from './StatusContext';
import { ContractProvider } from './ContractContext';

function Unknown() {
    return (
        <>
            <Alert variant="danger">
                <Alert.Heading>404 Not Found</Alert.Heading>
                <span>
                    The URL you are trying to access does not exist or an error
                    has occurred.
                </span>
                <hr />
                <pre>
                    You can head back to our <a href="/">homepage</a>.
                </pre>
            </Alert>
        </>
    );
}

function App() {
    return (
        <StatusProvider>
            <ChainProvider>
                <ContractProvider>
                    <Router>
                        <Navbar bg="dark" expand="lg" variant="dark">
                            <Container>
                                <Navbar.Brand href="/" className="">
                                    <span className="text-light">Digi</span>
                                    <span className="text-primary">
                                        Bitties
                                    </span>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link
                                            href="/mint"
                                            className="text-ligh"
                                        >
                                            Mint
                                        </Nav.Link>
                                        <Nav.Link
                                            href="/game"
                                            className="text-ligh"
                                        >
                                            Game
                                        </Nav.Link>
                                    </Nav>
                                    <Nav>
                                        <Nav.Link href="/claim">Claim</Nav.Link>
                                        <Nav.Link
                                            href="https://discord.gg/u5QHCzAmqB"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Support
                                        </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <Routes>
                            <Route exact path="/" element={<DApp />} />
                            <Route exact path="/mint" element={<DApp />} />
                            <Route path="/game" element={<ComingSoon />} />
                            <Route path="/claim" element={<Claim />} />
                            <Route path="/images" element={<Images />} />
                            <Route path="/*" element={<Unknown />} />
                        </Routes>
                    </Router>
                </ContractProvider>
            </ChainProvider>
        </StatusProvider>
    );
}

export default App;
