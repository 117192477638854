import React from 'react';
import NFTCalendar from './NFTCalendar.svg';
import OpenSea from './OpenSea.svg';
import Rarible from './Rarible.svg';
import X2Y2 from './X2Y2.svg';

export default function Footer() {
    return (
        <div className="container">
            <div className="row">
                <div
                    className="col col-12 mt-2 p-3 text-light bg-dark bg-gradient border-secondary border rounded-3 d-flex flex-row justify-content-around"
                    style={{ '--bs-bg-opacity': 0.75 }}
                >
                    <h1 className={'display-6 align-self-center'}>
                        As seen on...
                    </h1>
                    <a
                        href="https://nftcalendar.io"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            width={128}
                            height={128}
                            src={NFTCalendar}
                            alt={'NFT Calendar'}
                        />
                    </a>
                    <a
                        href="https://opensea.io/collection/digibitties-1337-collection"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            width={128}
                            height={128}
                            src={OpenSea}
                            alt={'OpenSea'}
                        />
                    </a>
                    <a
                        href="https://rarible.com/digibitties-1337/items"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            width={128}
                            height={128}
                            src={Rarible}
                            alt={'Rarible'}
                        />
                    </a>
                    <a
                        href="https://x2y2.io/collection/digibitties-1337-collection/items"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img width={128} height={128} src={X2Y2} alt={'X2Y2'} />
                    </a>
                </div>
            </div>
            <div className="row">
                <div
                    className="col col-12 mt-2 p-3 text-warning bg-dark bg-gradient border-secondary border rounded-3"
                    style={{ '--bs-bg-opacity': 0.75 }}
                >
                    The NFT art was imagined, designed, and created by Taz,
                    while the stack was created by Digitz. If you need help
                    visit{' '}
                    <a
                        href="https://discord.gg/u5QHCzAmqB"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our discord
                    </a>
                    .
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <footer className="d-flex flex-wrap justify-content-between align-items-top py-3 my-4 border-top container">
                        <p className="col-md-4 mb-0 text-muted">
                            © 2022 eContriver LLC
                        </p>
                        <a
                            href="/"
                            className="col col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="64"
                                height="64"
                                fill="currentColor"
                                className="bi"
                                viewBox="0 0 128 128"
                            >
                                <path d="M37.8,57.26c-5.49,0-9.96,4.47-9.96,9.96s4.47,9.96,9.96,9.96,9.96-4.47,9.96-9.96-4.47-9.96-9.96-9.96Z" />
                                <path d="M64,10.91h-10.62C30.02,10.91,10.91,30.02,10.91,53.38v21.23c0,23.36,19.11,42.47,42.47,42.47h10.62V10.91Zm-15.89,72.8c-2.99,1.88-6.52,2.98-10.31,2.98-10.74,0-19.48-8.74-19.48-19.48s8.74-19.48,19.48-19.48c3.64,0,7.04,1.02,9.96,2.77v-15.83c0-2.63,2.13-4.76,4.76-4.76s4.76,2.13,4.76,4.76v47.27c0,2.63-2.13,4.76-4.76,4.76-2,0-3.71-1.23-4.41-2.98Z" />
                                <path d="M75.48,86.7c2,0,3.71-1.23,4.41-2.98,2.99,1.88,6.52,2.98,10.31,2.98,10.74,0,19.48-8.74,19.48-19.48s-8.74-19.48-19.48-19.48c-3.64,0-7.04,1.02-9.96,2.77v-15.83c0-2.63-2.13-4.76-4.76-4.76s-4.76,2.13-4.76,4.76v47.27c0,2.63,2.13,4.76,4.76,4.76Zm14.72-29.44c5.49,0,9.96,4.47,9.96,9.96s-4.47,9.96-9.96,9.96-9.96-4.47-9.96-9.96,4.47-9.96,9.96-9.96Z" />
                            </svg>
                        </a>
                        {/* <div className="col-md-4 d-flex align-items-center">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi" viewBox="0 0 128 128">
                            <path d="M37.8,57.26c-5.49,0-9.96,4.47-9.96,9.96s4.47,9.96,9.96,9.96,9.96-4.47,9.96-9.96-4.47-9.96-9.96-9.96Z"/>
                            <path d="M64,10.91h-10.62C30.02,10.91,10.91,30.02,10.91,53.38v21.23c0,23.36,19.11,42.47,42.47,42.47h10.62V10.91Zm-15.89,72.8c-2.99,1.88-6.52,2.98-10.31,2.98-10.74,0-19.48-8.74-19.48-19.48s8.74-19.48,19.48-19.48c3.64,0,7.04,1.02,9.96,2.77v-15.83c0-2.63,2.13-4.76,4.76-4.76s4.76,2.13,4.76,4.76v47.27c0,2.63-2.13,4.76-4.76,4.76-2,0-3.71-1.23-4.41-2.98Z"/>
                            <path d="M75.48,86.7c2,0,3.71-1.23,4.41-2.98,2.99,1.88,6.52,2.98,10.31,2.98,10.74,0,19.48-8.74,19.48-19.48s-8.74-19.48-19.48-19.48c-3.64,0-7.04,1.02-9.96,2.77v-15.83c0-2.63-2.13-4.76-4.76-4.76s-4.76,2.13-4.76,4.76v47.27c0,2.63,2.13,4.76,4.76,4.76Zm14.72-29.44c5.49,0,9.96,4.47,9.96,9.96s-4.47,9.96-9.96,9.96-9.96-4.47-9.96-9.96,4.47-9.96,9.96-9.96Z"/>
                        </svg>
                    </a>
                    <span className="mb-3 mb-md-0 text-muted">© 2022 Company, Inc</span>
                </div> */}

                        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                            <li className="ms-3">
                                <a
                                    className="text-muted"
                                    href="https://twitter.com/DigiBitties"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        fill="currentColor"
                                        className="bi bi-twitter"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                    </svg>
                                    {/* <svg className="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg> */}
                                </a>
                            </li>
                            <li className="ms-3">
                                <a
                                    className="text-muted"
                                    href="https://discord.gg/u5QHCzAmqB"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        fill="currentColor"
                                        className="bi bi-discord"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                                    </svg>
                                    {/* <svg className="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg> */}
                                </a>
                            </li>
                            <li className="ms-3">
                                <a
                                    className="text-muted"
                                    href="https://www.youtube.com/channel/UCQFKc-mdAybY457jRD1IfbA"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        fill="currentColor"
                                        className="bi bi-youtube"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                    </svg>
                                    {/* <svg className="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg> */}
                                </a>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>
    );
}
