import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './ErrorHandler';
import Footer from './Footer';
import Header from './Header';
import { Messages } from './StatusContext';
import { ConveyorBelt } from './ConveyorBelt';

const ComingSoon = () => {
    return (
        <div className="ComingSoon">
            <ErrorBoundary FallbackComponent={ErrorHandler}>
                <Header />
                <div className="container" id="content">
                    <div className="row align-items-center justify-content-around">
                        <div
                            className="col media m-3 p-3 align-items-center rounded-3 border border-primary bg-dark bg-gradient text-light"
                            style={{ '--bs-bg-opacity': 0.8 }}
                        >
                            <div className="media-body p-2">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="mt-0 mb-1 text-warning display-6">
                                            <u>
                                                <b>Coming Soon</b>
                                            </u>
                                        </h5>
                                        <h6>
                                            Welcome to DigiBitties, we build
                                            things and stuff! This area is{' '}
                                            <u>currently</u> under construction,
                                            but coming soon! For updates
                                            join&nbsp;
                                            <a
                                                href="https://discord.gg/u5QHCzAmqB"
                                                className="text-light"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                our discord
                                            </a>
                                            .
                                        </h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr />
                                    <ConveyorBelt />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Messages />
                </div>
                <Footer />
            </ErrorBoundary>
        </div>
    );
};

export default ComingSoon;
