import bazooka from './bazooka.gif';
import React from 'react';

export function Bazooka() {
    return (
        <div className="col col-12 col-lg-3 p-3 d-flex justify-content-center">
            <img
                style={{
                    width: 256 + 'px',
                    height: 256 + 'px'
                }}
                src={bazooka}
                // className="ml-3"
                className="App-logo rounded-3 border border-dark border-2 d-block shadow-lg"
                alt="DigiBitties - Bazooka!"
            />
        </div>
    );
}
