import React from 'react';
import ErrorHandler from './ErrorHandler';
import { ErrorBoundary } from 'react-error-boundary';
import Footer from './Footer';
import Header from './Header';
import { useChain } from './ChainContext';
import { getFromId } from './DB1Networks';
import { ethers } from 'ethers';
import DB1_ABI from './DB1ABI.json';
import { Messages, useStatus } from './StatusContext';
import { Preview } from './Preview';
import { Bazooka } from './Bazooka';
import { useContract } from './ContractContext';
import { truncateAndPretify } from './TruncateAndPretify';
import Owned from './Owned';
import Spinner from 'react-bootstrap/Spinner';

function WithdrawForm() {
    const { addContractError, addMessage } = useStatus();
    const { state: chainState, correctNetworkSelected } = useChain();
    const { state: contractState } = useContract();

    const withdraw = () => {
        if (correctNetworkSelected() && contractState.contractBalance.eq(0)) {
            addMessage('Cannot withdraw when balance is zero.', 'warning');
        } else if (
            correctNetworkSelected() &&
            contractState.contractBalance.gt(0)
        ) {
            const contract = contractState.contract;
            contract
                .withdraw()
                .then((tx) => {
                    addMessage(
                        <div>
                            Withdraw transaction was successfully submitted,
                            waiting for block...{' '}
                            <Spinner
                                animation="border"
                                variant="success"
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>,
                        'success',
                        10000
                    );
                    tx.wait()
                        .then((receipt) => {
                            addMessage('Withdraw completed.', 'success');
                        })
                        .catch((error) => addContractError(contract, error));
                })
                .catch((error) => addContractError(contract, error));
        }
    };

    const account =
        chainState.accounts.length >= 1 ? chainState.accounts[0] : null;
    const isOwner =
        account &&
        contractState.owner &&
        contractState.owner?.toLowerCase() === account?.toLowerCase();

    if (
        chainState.connected &&
        isOwner &&
        correctNetworkSelected() &&
        chainState.accounts &&
        chainState.accounts.length
    ) {
        const balanceClassName =
            contractState.contractBalance > 0 ? '' : ' is-invalid';
        return (
            <div className="input-group w-100 mb-1">
                <span className="input-group-text" id="basic-addon3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </span>
                <input
                    type="text"
                    className={'form-control text-muted' + balanceClassName}
                    placeholder="The total amount in the contract"
                    aria-label="The total amount in the contract"
                    aria-describedby="basic-addon3"
                    readOnly={true}
                    value={truncateAndPretify(contractState.contractBalance, 4)}
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={withdraw}
                >
                    Withdraw
                </button>
            </div>
        );
    }
}

function ClaimForm() {
    const { addContractError, addMessage } = useStatus();
    // const { addError, addContractError, addMessage } = useStatus();
    const { state: chainState, correctNetworkSelected } = useChain();
    // const { state: contractState } = useContract();
    const [signature, setSignature] = React.useState('');

    const claim = () => {
        if (correctNetworkSelected()) {
            if (signature === '') {
                addMessage(
                    'First paste in the code that was sent to you.',
                    'warning'
                );
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const db1 = getFromId(chainState.chainId);
            const address = db1.address;
            const contract = new ethers.Contract(address, DB1_ABI, signer);
            contract
                .claim(signature)
                .then((a) => {
                    console.log("it's been claimed...: %o", a);
                    addMessage('Claiming completed - NFT minted.', 'success');
                })
                .catch((error) => addContractError(contract, error));
            // 0xA65Ad146A873526F101468A1C60B5bDB67a19ee2
            // 0x2623523aa2fd1fc7c8c196f9dd44a5df6a0d8825e12fc171c0b2d41e393300b6365f908c750571b06494ee844e7f422ba5fbc130f0da9af70986e4f6c1a8168a1b
        }
    };

    // const isValidSignature = React.useCallback(() => {
    //     try {
    //         const message = getMessageToSign(chainState.accounts[0]);
    //         const actualAddress = ethers.utils.verifyMessage(
    //             message,
    //             signature
    //         );
    //         return contractState.owner === actualAddress;
    //     } catch (e) {
    //         addError(e);
    //     }
    // }, [chainState.accounts, contractState.owner]);

    if (
        chainState.connected &&
        correctNetworkSelected() &&
        chainState.accounts &&
        chainState.accounts.length
    ) {
        // const sigClassName = 'form-control';
        const signatureClassName = ''; // isValidSignature() ? '' : ' is-invalid';
        return (
            <div className="input-group w-100 mb-1">
                <span className="input-group-text" id="basic-addon1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-file-earmark-lock"
                        viewBox="0 0 16 16"
                    >
                        <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0zM7 7v1h2V7a1 1 0 0 0-2 0zM6 9.3v2.4c0 .042.02.107.105.175A.637.637 0 0 0 6.5 12h3a.64.64 0 0 0 .395-.125c.085-.068.105-.133.105-.175V9.3c0-.042-.02-.107-.105-.175A.637.637 0 0 0 9.5 9h-3a.637.637 0 0 0-.395.125C6.02 9.193 6 9.258 6 9.3z"></path>
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"></path>
                    </svg>
                </span>
                <input
                    type="text"
                    className={'form-control' + signatureClassName}
                    placeholder="Enter the code that was given to you to claim!"
                    aria-label="Enter the code that was given to you to claim!"
                    aria-describedby="basic-addon1"
                    value={signature}
                    onChange={(e) => setSignature(e.target.value)}
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={claim}
                >
                    Claim
                </button>
            </div>
        );
    }
}

function getMessageToSign(targetAddress) {
    const message = ethers.utils.hexZeroPad(targetAddress, 32); // .toString().toLowerCase()
    return ethers.utils.arrayify(message);
    // const message = `DigiBitties 1337 Collection free mint ${targetAddress}`;
}

function SignForm() {
    const { addError } = useStatus();
    const { state: chainState } = useChain();
    const { state: contractState } = useContract();
    const [targetAddress, setTargetAddress] = React.useState('');
    const [signedAddress, setSignedAddress] = React.useState('');
    // const [owner, setOwner] = useState(null);

    const sign = () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            signer
                .signMessage(getMessageToSign(targetAddress))
                .then((signedMessage) => {
                    setSignedAddress(signedMessage);
                    // const sig = ethers.utils.splitSignature(signedMessage);
                    // const contract = new ethers.Contract(address, DB1_ABI, signer);
                    // contract
                    //     .verifyString(message, sig.v, sig.r, sig.s)
                    //     .then((recovered) => {
                    //         console.log(recovered);
                    //         // "0x14791697260E4c9A71f18484C9f997B308e59325"
                    //     });
                })
                .catch((error) => addError(error));
        } catch (error) {
            addError(error);
        }
    };

    // useEffect(() => {
    //     if (chainState.connected && correctNetwork()) {
    //         const db1 = getFromId(chainState.chainId);
    //         const address = db1.address;
    //         const provider = new ethers.providers.Web3Provider(window.ethereum);
    //         const signer = provider.getSigner();
    //         const contract = new ethers.Contract(address, DB1_ABI, signer);
    //         // contract.owner().then((owner) => setOwner(owner));
    //     }
    // }, [chainState.accounts]);

    const account =
        chainState.accounts.length >= 1 ? chainState.accounts[0] : null;
    if (
        chainState.connected &&
        contractState.owner &&
        account &&
        contractState.owner?.toLowerCase() === account?.toLowerCase()
    ) {
        return (
            <div className="row align-middle">
                <div className="input-group w-100 mb-1">
                    <span className="input-group-text" id="basic-addon1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pen"
                            viewBox="0 0 16 16"
                        >
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"></path>
                        </svg>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="User address to sign for"
                        aria-label="User address to sign for"
                        aria-describedby="basic-addon1"
                        value={targetAddress}
                        onChange={(e) => setTargetAddress(e.target.value)}
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={sign}
                    >
                        Sign
                    </button>
                </div>
                <div className="input-group w-100 mb-1">
                    <span className="input-group-text" id="basic-addon2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-key"
                            viewBox="0 0 16 16"
                        >
                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"></path>
                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                        </svg>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        value={signedAddress}
                        placeholder="Signed message data output"
                        aria-label="Signed message data output"
                        aria-describedby="basic-addon2"
                        readOnly={true}
                    />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

function MiddleRow() {
    return (
        <div className="row align-items-center justify-content-around">
            <Preview />
            <div
                className="col media m-3 p-3 d-flex align-items-center rounded-3 border border-primary bg-dark bg-gradient text-light"
                style={{ '--bs-bg-opacity': 0.75 }}
            >
                <div className="media-body p-2">
                    <div className="row">
                        <div className="col">
                            <h5 className="mt-0 mb-1 text-warning display-6">
                                <u>
                                    <b>Claim a 1337</b>
                                </u>
                            </h5>
                            <h6>Mint 10 and join discord!</h6>
                        </div>
                    </div>
                    <hr />
                    For an account to be eligible to claim a <u>free</u>{' '}
                    DigiBitty from the 1337 Collection, that account must be the
                    original minter of 10 or more DigiBitties from the 1337
                    Collection, and must join{' '}
                    <a
                        href="https://discord.gg/zAaY4n5zM8"
                        className="text-light"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our discord
                    </a>
                    .
                    <br />
                    <br />
                    <p>
                        A post must be made in the <b>#db-claim</b> channel with
                        the requesting account address in the post. Digitz will
                        respond to you (should be pretty quick) and will give
                        you your code. The code will only work until all 1337s
                        have been minted, so <u>don&apos;t delay</u>!
                    </p>
                </div>
            </div>
            <Bazooka />
        </div>
    );
}
export default function Claim() {
    return (
        <div className="claim">
            <ErrorBoundary FallbackComponent={ErrorHandler}>
                <Header />
                <div className="container" id="content">
                    <MiddleRow />
                    <ClaimForm />
                    <SignForm />
                    <WithdrawForm />
                    <Owned />
                </div>
                <Footer />
                <Messages />
            </ErrorBoundary>
        </div>
    );
}
